<template>
    <el-container class="home-container">
        <el-header style="border-bottom: 1px solid #D9D5D2;">
            <div>
                <span>铺货折扣管理系统</span>
            </div>
            <!-- 测试用切换角色下拉框 start -->
          <!--<span class="currentRoleLabel">当前角色</span>
            <el-select @change="roleChange"
                       v-model="currentRoleId"
                       filterable
                       placeholder
                       class="currentRoleSelect">
                <el-option v-for="item in roleDDL"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
            </el-select>-->
            <!--<span class="currentRoleLabel">当前用户</span>
            <el-select @change="userChange"
                       v-model="currentUserEmail"
                       filterable
                       placeholder
                       class="currentRoleSelect">
                <el-option v-for="item in testUserDDL"
                           :key="item.userId"
                           :label="item.userName"
                           :value="item.userEmail"></el-option>
            </el-select>
            <!-- 测试用切换角色下拉框 end -->
            <img src="../assets/Syngenta logo.png" />
        </el-header>
        <el-header style="height:40px; border-bottom: 1px solid #D9D5D2;background-color:#FAFAFA">
            <el-menu mode="horizontal"
                     background-color="#ffffff"
                     text-color="#5F7800"
                     active-text-color="#fff"
                     unique-opened
                     :collapse="isCollapse"
                     :collapse-transition="false"
                     router
                     :default-active="activePath">
                <el-submenu :index="item.id+''" v-for="item in menulist" :key="item.id">
                    <template slot="title">
                        <!--<i class="el-icon-location"></i>-->
                        <span>{{item.authName}}</span>
                    </template>
                    <el-menu-item :index="'/'+subItem.path"
                                  v-for="subItem in item.children"
                                  :key="subItem.id"
                                  @click="saveNavState(subItem.path,subItem.id)">
                        <!-- 二级菜单 -->
                        <template slot="title">
                            <!--<i class="el-icon-menu"></i>-->
                            <span>{{subItem.authName}}</span>
                        </template>
                    </el-menu-item>
                </el-submenu>
            </el-menu>
        </el-header>
        <el-container>
            <el-main>
                <router-view></router-view>
                <div class="homeBottom"></div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    export default {
        data() {
            return {
                menulist: [],
                isCollapse: false,
                activePath: "",
                currentRoleId: null,
                roleDDL: [],
                currentUserEmail: null,
                testUserDDL: [],
                flag: false
            };
        },
        created() {
            this.login();
            this.activePath = window.sessionStorage.getItem("activePath");
            this.getRoleDDL();
            //this.getTestUserList();
        },
        methods: {
            getQueryVariable(variable) {
                var q = window.location.href.split('?');
                if (q.length > 1) {
                    var query = q[1];
                    var vars = query.split("&");
                    for (var i = 0; i < vars.length; i++) {
                        var pair = vars[i].split("=");
                        if (pair[0] === variable) { return pair[1]; }
                    }
                }
                return '';
            },
            async login() {
                //for test
                //window.sessionStorage.setItem("dmstoken", "cecily.chen@syngenta.com");
                //window.sessionStorage.setItem("dmstoken", "Lance.fang@syngenta.com");
                //this.flag = true;
                //this.getUserInfo();
                //end for tst
                //for sso
                let tokenStr = window.sessionStorage.getItem("dmstoken");
                if (!tokenStr) {
                    let token = this.getQueryVariable('token');
                    console.log(token);
                    if (token != '') {
                        this.flag = true;
                        window.sessionStorage.setItem("dmstoken", token);
                        this.getUserInfo();
                    } else {
                        const { result: ssoLoginUrl } = await this.$http.get(
                            "/Log/GetSSOLoginUrl?environment=Prod"   //生产环境
                        );
                        console.log(ssoLoginUrl);
                        window.location.href = ssoLoginUrl;
                    }
                } else {
                    this.flag = true;
                    this.getUserInfo();
                }
                //end of for sso
                //else {
                //    const { result: res } = await this.$http.fetchData("/Login/ValidLoginUser");
                //    if (res != "") {
                //        window.sessionStorage.setItem("dmstoken", res);
                //        this.getUserInfo();
                //    } else {
                //        window.sessionStorage.removeItem("dmstoken");
                //        this.isCollapse = false;
                //        return this.$message.error("抱歉，您没有权限");
                //    }
                //}
            },
            async getTestUserList() {
                const { result: res } = await this.$http.fetchData("user/GetUserList", { userName: "test" });
                this.testUserDDL = res;
                if (!!this.currentUserEmail) { } else {
                    const { result: user } = await this.$http.fetchData(
                        "user/GetCurrentUser"
                    );
                    this.currentUserEmail = user;
                }
            },
            async getUserInfo() {
                const { result: res } = await this.$http.get("Home/GetUserInfo");
                this.currentRoleId = res[0].roleIds.split(";")[0];
                this.currentLoginId = res[0].loginId;
                if (!!res[0].loginId) {
                    this.getMenuList(res[0].loginId);
                }
            },
            async getMenuList(loginId) {
                const { result: res } = await this.$http.fetchData("Home/GetMenu", {
                    loginId: loginId
                });
                this.menulist = res;
                const firstFromURL = window.sessionStorage.getItem("firstFromURL"); //调回第一次进来的路由
                if(!!firstFromURL){
                    this.$router.push(firstFromURL)
                    window.sessionStorage.removeItem("firstFromURL");
                }
            },

            toggleCollapse() {
                //菜单折叠展开
                this.isCollapse = !this.isCollapse;
            },
            saveNavState(activePath, activeMenuId) {
                window.sessionStorage.setItem("activePath", "/" + activePath);
                window.sessionStorage.setItem("activeMenuId", +activeMenuId);
                this.activePath = activePath;
            },
            async getRoleDDL() {
                /*    let token = window.sessionStorage.getItem('dmstoken').toString()
                   this.$http.get('/Home/GetSelectList?lookup=userRole', {
               headers: {
                 authorization: `Bearer ${token}`,
               }}); */


                const { result: roleList } = await this.$http.get(
                    "/Home/GetSelectList?lookup=userRole"
                );
                this.roleDDL = roleList;
            },
            async roleChange() {
                const { result: res } = await this.$http.fetchData(
                    "user/UpdateUserRole",
                    {
                        loginId: this.currentLoginId,
                        roleId: this.currentRoleId
                    }
                );
                if (res == "success") {
                    this.$message.success("切换角色成功");
                }
                this.$router.push({ name: 'home' })
                this.$router.go(0)
            },
            async userChange() {
                console.log(this.currentUserEmail);
                const { result: res } = await this.$http.fetchData(
                    "user/CacheCurrentUser",
                    {
                        userEmail: this.currentUserEmail
                    }
                );
                if (res == "success") {
                    this.$message.success("切换用户成功");
                }
            }
        }
    };
</script>

<style lang="less">
    .home-container {
        height: calc(100vh);
    }

    .el-header {
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
        align-items: center;
        /*color: #fff;*/
        font-size: 24px;
        > div {
        display: flex;
        align-items: center;
        span {
        color: #5f7800;
        line-height: 50px;
        }
    img {
        height: 50px;
        /*width:50px;*/
        }
        }
    }

    .el-main {
        background-color: #fafafa;
    }

    .toggle-button {
        background-color: #aab400;
        font-size: 10px;
        line-height: 24px;
        /*color: #fff;*/
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }
    /*子菜单active背景颜色*/
    .el-menu--horizontal .el-menu .el-menu-item.is-active {
        background-color: #aab400 !important;
    }
    /*子菜单鼠标悬停颜色*/
    .el-menu--horizontal .el-menu :hover {
        background-color: #aab400 !important;
        color: #fff !important;
    }
    /*主菜单鼠标悬停颜色*/
    .el-menu--horizontal > .el-submenu :hover {
        background-color: #aab400 !important;
        color: #fff !important;
    }

    .el-submenu__title {
        height: 40px !important; /*menu高度*/
        line-height: 40px !important;
        border-bottom: none !important;
        border-right: 1px solid #d9d5d2 !important;
        border-bottom: 1px solid #d9d5d2 !important;
        border-top: 1px solid #d9d5d2 !important;
    }

        .el-submenu__title i {
            display: none !important; /*去掉箭头*/
        }

    .el-menu li {
        width: 200px; /*所有menu宽度*/
        font-size: 14px;
    }

    .el-header ul {
        border-left: 1px solid #d9d5d2 !important;
    }

    .el-menu li.is-active {
        background-color: #aab400 !important;
    }

        .el-menu li.is-active .el-submenu__title {
            background-color: #aab400 !important;
        }

    .homeBottom {
        widows: 100%;
        height: 8vh;
    }

    .currentRoleLabel {
        position: relative;
        left: 470px;
        font-size: 20px;
    }

    .currentRoleSelect {
        position: relative;
        left: 230px;
        width: 170px;
    }
</style>
