<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>预测分配表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="search-item" shadow="never">
      <span>搜索条件</span>
    </el-card>

    <el-card class="search-form" shadow="never">
      <!-- 搜索表单 -->
      <el-form>
        <el-row>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>品牌</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select v-model="searchList.brand" filterable placeholder>
                    <el-option
                      v-for="item in brandDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>产品</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select v-model="searchList.productName" filterable placeholder>
                    <el-option
                      v-for="item in productNameDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>产品线</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select v-model="searchList.productLine" filterable placeholder>
                    <el-option
                      v-for="item in productLineDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>产品代码</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select v-model="searchList.agicodeId" filterable placeholder>
                    <el-option
                      v-for="item in agicodeDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>大区</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select v-model="searchList.areaId" filterable placeholder @change="areaChange">
                    <el-option
                      v-for="item in areaDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>小区</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select v-model="searchList.salegroupId" filterable placeholder @change="handleSelectChange">
                    <el-option
                      v-for="item in saleGroupDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>月份</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select v-model="searchList.month" filterable placeholder>
                    <el-option
                      v-for="item in monthDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <label>年份</label>
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-form-item>
                  <el-select
                    v-model="searchList.year"
                    filterable
                    placeholder
                    @change="handleSelectChange"
                  >
                    <el-option
                      v-for="item in yearDDL"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row>
              <el-col :span="6">
                <el-form-item>
                  <el-button @click="getDistributionList(true)" type="primary">搜索</el-button>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <el-button type="primary" @click="resetSearch">重置</el-button>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <el-button
                    v-if="haveEditAccess&&isBudgetLocked"
                    @click="editDistribution"
                    type="primary"
                  >编辑</el-button>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <el-button
                    v-if="haveEditAccess&&isBudgetLocked"
                    @click="saveDistribution"
                    type="primary"
                  >保存</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row class="lastBtnRow">
          <el-col :span="8" :offset="16">
              <el-row>
                  <el-col :span="6" v-if="haveEditAccess&&isBudgetLocked">
                      <el-form-item>
                          <el-button @click="importDialogVisible = true" type="primary">导入</el-button>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6" v-if="isBudgetLocked">
                      <el-form-item>
                          <el-button :disabled="listTotalNum>5000"
                                     @click="exportDistribution(false)"
                                     :type="listTotalNum>5000? 'disabled':'primary'">实时导出</el-button>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6" v-if="isBudgetLocked">
                      <el-form-item>
                          <el-button @click="exportDistribution(true)" type="primary">邮件通知导出</el-button>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6" v-if="costShow">
                      <el-form-item>
                          <el-button @click="exportDistributionYearCompare" type="primary">导出年度比较</el-button>
                      </el-form-item>
                  </el-col>
                  
              </el-row>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <el-card class="search-item" shadow="never">
      <span>查询结果</span>
    </el-card>
    <el-card class="result-table" shadow="never">
        <el-table :data="distributionList" border stripe height="600">
            <el-table-column v-for="item in agiIteratorList"
                             :key="item"
                             :label="'产品代码'+ String.fromCharCode(64+item)"
                             :cell-style="{padding:'2px'}">
                <template slot-scope="scope">
                    <span>{{ scope.row.agicodeList[item-1]? scope.row.agicodeList[item-1].agicodeNo:""}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="productBrand" label="品牌"></el-table-column>
            <el-table-column prop="productSize" label="规格"></el-table-column>
            <el-table-column prop="productName" label="产品"></el-table-column>
            <el-table-column prop="productLine" label="产品线"></el-table-column>
            <el-table-column prop label="全国" width="35px"></el-table-column>
            <el-table-column prop="areaName" label="大区"></el-table-column>
            <el-table-column prop="saleGroupName" label="小区"></el-table-column>
            <el-table-column prop="year" label="年份" width="60px"></el-table-column>
            <el-table-column prop="monthLabel" label="月份" width="50px"></el-table-column>
            <el-table-column label="预测MDR总金额">
                <template v-slot="scope">
                    {{scope.row.mdrAmountSum|thousandth}}
                </template>
            </el-table-column>

            <el-table-column label="预测MDR金额" width="100px">
                <template slot-scope="scope">
                    <span v-if="!haveEditAccess"> {{scope.row.mdrAmount|thousandth}}</span>
                    <div class="el-input--small" v-if="haveEditAccess">
                        <currency-input v-model="scope.row.mdrAmount" class="el-input__inner currencyInput" @change="mdrChange(scope.row)" :currency="null" v-bind:disabled="inputMDRDisable ||scope.row.month<currentMonth"></currency-input>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="预测现金折扣金额">
                <template v-slot="scope">
                    {{scope.row.cashDiscountAmount|thousandth}}
                </template>
            </el-table-column>
            <!-- <el-table-column label="FOC金额">
         <template slot-scope="scope">
             {{scope.row.foc}}
         </template>
     </el-table-column>-->
            <el-table-column label="预测铺货折扣金额">
                <template v-slot="scope">
                    {{scope.row.discountAmount|thousandth}}
                </template>
            </el-table-column>
            <!--<el-table-column label="预测基本折扣%">
        <template slot-scope="scope">
            {{scope.row.basicDiscount}}%
        </template>
    </el-table-column>-->
            <el-table-column label="预测MDR%">
                <template v-slot="scope">
                    {{scope.row.mdrPercent}}%
                </template>
            </el-table-column>
            <el-table-column label="预测现金折扣%">
                <template slot-scope="scope">
                    <span>{{scope.row.cashPercent}}%</span>
                </template>
            </el-table-column>
            <!--<el-table-column label="预测FOC%">
                <template slot-scope="scope">
                    <span v-if="!haveEditAccess">{{scope.row.focPercent}}%</span>
    <div class="el-input--small" v-if="haveEditAccess">
        <currency-input v-model="scope.row.focPercent" class="el-input__inner currencyInput" @change="FOCChange(scope.row)" :currency="null" v-bind:disabled="inputDisable"></currency-input>
    </div>
                </template>
            </el-table-column>
            <el-table-column label="有条件折扣%">
                <template slot-scope="scope">
                    <span v-if="!haveEditAccess">{{scope.row.conditionDiscount}}%</span>
                    <div class="el-input--small" v-if="haveEditAccess">
                        <currency-input v-model="scope.row.conditionDiscount" class="el-input__inner currencyInput" @change="conditionChange(scope.row)" :currency="null" v-bind:disabled="inputDisable"></currency-input>
                    </div>
                </template>
            </el-table-column>-->
            <!--<el-table-column label="是否为额外折扣"  width="120px">
        <template slot-scope="scope">
            <span v-if="!netPriceShow">{{scope.row.isconditionDiscount > 0 ?'是':'否'}}</span>
            <el-switch
                       v-model="scope.row.isconditionDiscount"
                       active-color="#AAB400"
                       active-text="是"
                       inactive-text="否"
                       v-if="netPriceShow"
                       v-bind:disabled="inputDisable"
                       @change="conditionChange(scope.row)"
                        ></el-switch>
        </template>
    </el-table-column>-->
            <el-table-column label="预测-发票价" width="120px">
                <template slot-scope="scope">
                    <span v-if="!haveEditAccess">{{scope.row.billingPrice}}</span>
                    <!--             <el-input
              v-bind:disabled="inputDisable"
              v-if="haveEditAccess"
              v-model="scope.row.billingPrice"
              @change="billingPriceChange(scope.row)"
              size="small"
            ></el-input> -->
                    <div class="el-input--small" v-if="haveEditAccess">
                        <currency-input v-model="scope.row.billingPrice" class="el-input__inner currencyInput" @change="billingPriceChange(scope.row)" :currency="null" v-bind:disabled="inputDisable"></currency-input>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="预测-即时铺货折扣%">
                <template slot-scope="scope">
                    <span v-if="!haveEditAccess">  {{scope.row.discountPercent}}%</span>
                    <div class="el-input--small" v-if="haveEditAccess">
                        <currency-input v-model="scope.row.discountPercent" class="el-input__inner currencyInput" @change="discountPercentChange(scope.row)" :currency="null" v-bind:disabled="inputDisable"></currency-input>
                    </div>
                </template>
            </el-table-column>

            <el-table-column v-for="item in otherDiscountHeader"
                             :key="item.headerName"
                             :label="item.headerName"
                             width="100px">
                <template slot-scope="scope">
                    <span v-if="!haveEditAccess">{{ scope.row.otherDiscountList[scope.row.otherDiscountList.findIndex(e=>e.header_Id == item.headId)].otherDiscountValue}}</span>
                    <span v-show="!haveEditAccess&&item.isPercentClass=='iconfont icon-percent'">%</span>
                    <el-input v-if="haveEditAccess"
                              v-bind:disabled="inputDisable"
                              v-model="scope.row.otherDiscountList[scope.row.otherDiscountList.findIndex(e=>e.header_Id == item.headId)].otherDiscountValue"
                              @change="otherDiscountChange(scope.row)"
                              :suffix-icon="item.isPercentClass"
                              size="small"></el-input>
                </template>
            </el-table-column>

            <el-table-column v-if="netPriceShow" label="预测-净价格" key="netPrice">
                <template v-slot="scope">
                    {{scope.row.netPrice|thousandth}}
                </template>
            </el-table-column>
            <el-table-column v-if="netPriceShow" label="预测-实际净价格" key="netPriceShow">
                <template v-slot="scope">
                    {{scope.row.saleNetPrice|thousandth}}
                </template>
            </el-table-column>
            <el-table-column label="预测-销量">
                <template v-slot="scope">
                    {{scope.row.sales|thousandth}}
                </template>
            </el-table-column>

            <el-table-column label="预测-净销售">
                <template v-slot="scope">
                    {{scope.row.netSales|thousandth}}
                </template>
            </el-table-column>
            <el-table-column label="预测-毛销售">
                <template v-slot="scope">
                    {{scope.row.grossSales|thousandth}}
                </template>
            </el-table-column>
            <el-table-column label="预测-利润">
                <template v-slot="scope">
                    {{scope.row.profit|thousandth}}
                </template>
            </el-table-column>
            <el-table-column v-if="costShow" label="成本" width="120px" key="cost">
                <template slot-scope="scope">
                    <span v-if="!haveEditAccess">{{scope.row.cost|thousandth}}</span>
                    <!--             <el-input
              @change="costChange(scope.row)"
              v-bind:disabled="inputDisable"
              v-if="haveEditAccess"
              v-model="scope.row.cost"
              size="small"
            ></el-input> -->
                    <div class="el-input--small" v-if="haveEditAccess">
                        <currency-input v-model="scope.row.cost" class="el-input__inner currencyInput" @change="costChange(scope.row)" :currency="null" v-bind:disabled="inputDisable"></currency-input>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="上年度铺货折扣%">
                <template slot-scope="scope">
                    {{scope.row.lastYearDiscount}}%
                </template>
            </el-table-column>
            <el-table-column label="上年度销量">
                <template v-slot="scope">
                    {{scope.row.lastYearSales|thousandth}}
                </template>
            </el-table-column>

            <!-- <el-table-column label="预测销量调整申请">
         <template v-slot="scope">
             {{scope.row.requestDetailSales|thousandth}}
         </template>
     </el-table-column>
     <el-table-column label="预测销量调整差额">
         <template v-slot="scope">
             {{scope.row.saleGap|thousandth}}
         </template>
     </el-table-column>-->

            <el-table-column label="铺货折扣更新时间">
                <template slot-scope="scope">
                    <span>{{scope.row.requestTime|dateFormat}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="requestReason" label="铺货折扣调整原因"></el-table-column>
        </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[10, 20, 50]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :hide-on-single-page="true"
        :total="listTotalNum"
      ></el-pagination>

      <el-dialog
        title="导入预测数据"
        :visible.sync="importDialogVisible"
        width="40%"
        @close="importDialogClosed"
        :close-on-click-modal="false"
      >
        <el-form ref="importFormRef" label-width="100px">
          <el-form-item label="上传文件：">
            <el-upload
              class="upload"
              ref="upload"
              action
              :file-list="fileList"
              :multiple="false"
              :show-file-list="true"
              :limit="1"
              :auto-upload="false"
              accept=".xlsx"
            >
              <el-button slot="trigger" type="primary">选取文件</el-button>
              <div slot="tip" class="el-upload__tip">只能上传一个文件，需要先删除已选择的文件，然后重新选择</div>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="importDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitUpload">确定上传</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      brandDDL: [],
      productNameDDL: [],
      productLineDDL: [],
      agicodeDDL: [],
      areaDDL: [],
      saleGroupDDL: [],
      monthDDL: [],
      yearDDL: [],
      searchList: {},
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 10
      },
      listTotalNum: 0,
      distributionList: [],
      agiIteratorList: [],
      agiListMaxLength: 0,
      otherDiscountHeader: [],
      haveEditAccess: false,
      isLoginUserAsAreaMarket: false,
      costShow: false,
      netPriceShow: false,
        isBudgetLocked: false,
      inputDisable: true,
      inputMDRDisable: true,
      downloadFileName: "",
      importDialogVisible: false,
      fileList: [],
        currentYear: null,
        currentMonth: null
    };
  },
  created() {
    this.getDDL();
    this.getCurrentDate();
    this.getLoginUserInfo();
    this.getDistributionList(true);
    this.checkBudgetLock();
  },
  methods: {
    async getDDL() {
      const { result: brandList } = await this.$http.get(
        "/Home/GetSelectList?lookup=brand"
      );
      const { result: productName } = await this.$http.get(
        "/Home/GetSelectList?lookup=productName"
      );
      const { result: productLine } = await this.$http.get(
        "/Home/GetSelectList?lookup=productLine"
      );
      const { result: agicode } = await this.$http.get(
        "/Home/GetSelectList?lookup=agicode"
      );
      const { result: area } = await this.$http.get(
        "/Home/GetSelectList?lookup=userArea"
      );
      const { result: saleGroup } = await this.$http.get(
        "/Home/GetSelectList?lookup=salgegroup"
      );
      const { result: month } = await this.$http.get(
        "/Home/GetSelectList?lookup=NormalMonth"
      );
      const { result: year } = await this.$http.get(
        "/Home/GetSelectList?lookup=NormalYear"
      );
      this.brandDDL = brandList;
      this.productNameDDL = productName;
      this.productLineDDL = productLine;
      this.agicodeDDL = agicode;
      this.areaDDL = area;
      this.saleGroupDDL = saleGroup;
      this.monthDDL = month;
      this.yearDDL = year;
    },
    async resetSearch() {
      this.searchList = [];
      this.searchList.year = this.currentYear;
      const { result: saleGroup } = await this.$http.get(
        "/Home/GetSelectList?lookup=salgegroup"
      );
      this.saleGroupDDL = saleGroup;
    },
    /* 获取列表 */
    async getDistributionList(fromSearch) {
      if (fromSearch) {
        this.queryInfo.pagenum = 1;
      }
      const { result: month } = await this.$http.get(
        "/Home/GetSelectList?lookup=NormalMonth"
      );
      this.monthDDL = month;
      const { result: res } = await this.$http.fetchData(
        "Forecast/GetForecastDistribution",
        {
          brandName: this.searchList.brand,
          productName: this.searchList.productName,
          productLine: this.searchList.productLine,
          agicodeId: !this.searchList.agicodeId
            ? 0
            : +this.searchList.agicodeId,
          areaId: !this.searchList.areaId ? 0 : +this.searchList.areaId,
          saleGroupId: !this.searchList.salegroupId
            ? 0
            : +this.searchList.salegroupId,
          month: this.searchList.month,
          year: this.searchList.year,
          pageNumber: this.queryInfo.pagenum - 1,
          pageSize: this.queryInfo.pagesize,
          isExport: false
        }
      );
      if (res.forecasts.length > 0) {
        this.otherDiscountHeader = [];
        res.forecasts.forEach(e => {
          e.agicodeList = [];
          e.isChanged = false;
            e.monthLabel = this.monthDDL.find(x => x.value == e.month).label;
            if (e.isconditionDiscount == 1) {
                e.isconditionDiscount = true;
            }
            else {
                e.isconditionDiscount = false;
            };
        });
        res.forecasts.forEach(e => {
          let filterAgi = res.agiCodes.filter(
            item => item.productId == e.productId
          );
          let filterOtherDiscount = res.othersFcstValues.filter(
            x => x.forecastId == e.forecastId
          );
          if (filterOtherDiscount.length == 0) {
            console.log(1);
            filterOtherDiscount = res.othersFcstValues.filter(
              x => x.forecastId == 0
            );
          }
          console.log(filterOtherDiscount);
          e.otherDiscountList = [];

          for (let i = 0; i < filterOtherDiscount.length; i++) {
            e.otherDiscountList.push({
              header_Id: filterOtherDiscount[i].otherDiscountHeadId,
              otherDiscountValue: filterOtherDiscount[i].otherDiscountValue,
              otherDiscountValueId: filterOtherDiscount[i].otherDiscountValueId,
              forecastId: e.forecastId,
              year: filterOtherDiscount[i].otherDiscountYear
            });
          }
          //产品代码
          for (let i = 0; i < filterAgi.length; i++) {
            e.agicodeList.push({ agicodeNo: filterAgi[i].agicodeNo });
          }
          //铺货调整
          e.requestDetailSales = 0;
          //e.saleBalance = "";
          //e.mdrAmountSum = "";
          //e.mdrAmount = "";
          //e.cashDiscountAmount = "";
          //e.discountAmount = "";
          e.requestTime = e.requestTime;
          e.requestReason = e.requestReason;

          let filterFcstAdjustments = res.fcstAdjustments.filter(
            y => y.forecastId == e.forecastId
          );
          if (filterFcstAdjustments.length > 0) {
            e.requestDetailSales = filterFcstAdjustments[0].requestDetailSales;
            //e.requestTime = filterFcstAdjustments[0].requestTime;
            //e.requestReason = filterFcstAdjustments[0].requestReason;
          }

        /*   let caculateBalance = e.sales - e.requestDetailSales;
          e.saleBalance = e.requestDetailSales == 0 ? 0 : caculateBalance; */
            e.mdrAmount = e.forecastMDR; /*this.convertDecimal(
            (e.mdrPercent / 100) * e.billingPrice
            );*/
         
          e.mdrAmountSum = e.mdrAmount * e.sales;
          
          e.discountAmount = this.convertDecimal(
            (e.discountPercent / 100) * e.billingPrice
            );
            e.cashDiscountAmount = e.discountAmount - e.mdrAmount;/*this.convertDecimal(
                (e.cashPercent / 100) * e.billingPrice
            );*/
        });

        this.agiListMaxLength = res.agiCodeMaxLength;
        this.agiIteratorList = [];
        for (let i = 0; i < this.agiListMaxLength; i++) {
          this.agiIteratorList.push(i + 1);
        }
        this.distributionList = res.forecasts;
        this.listTotalNum = res.total;

        /* 其他折扣 */
        let ODisHeaderTemplate = [];
        let ODisHeaderTempObj = {};
        ODisHeaderTemplate = res.othersFcstValues.reduce(function(item, next) {
          ODisHeaderTempObj[next.otherDiscountHeadId]
            ? ""
            : (ODisHeaderTempObj[next.otherDiscountHeadId] =
                true && item.push(next));
          return item;
        }, []);
        var isPercentClass = "";
        for (let i = 0; i < ODisHeaderTemplate.length; i++) {
          if (ODisHeaderTemplate[i].otherDiscountHeadName.indexOf("%") > -1) {
            isPercentClass = "iconfont icon-percent";
          } else {
            isPercentClass = "";
          }
          if (this.isLoginUserAsAreaMarket) {
            ODisHeaderTemplate = ODisHeaderTemplate.filter(
              e => e.otherDiscountIsShow == 1
            );
          }

          this.otherDiscountHeader.push({
            headerName: ODisHeaderTemplate[i].otherDiscountHeadName,
            isShow:
              ODisHeaderTemplate[i].otherDiscountIsShow == 1 ? true : false,
            headId: ODisHeaderTemplate[i].otherDiscountHeadId,
            isPercentClass: isPercentClass
          });
          this.otherDiscountHeader.sort((a, b) => a.headId - b.headId);
        }
      } else {
        this.distributionList = [];
        this.$message.warning("没有对应的搜索数据");
      }
    },
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getDistributionList(false);
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getDistributionList(false);
    },
    async getLoginUserInfo() {
      const { result: res } = await this.$http.get("Home/GetUserInfo");
      if (!!res[0].loginId) {
        var userRoleIds = res[0].roleIds.split(";");
        var currentMenuId = window.sessionStorage.getItem("activeMenuId");
        if (userRoleIds.findIndex(e => e == 1) > -1) {
          this.lockBtnShow = true;
        }
        if (
          userRoleIds.findIndex(e => e == 1) > -1 ||
          userRoleIds.findIndex(e => e == 2) > -1
        ) {
          //系统管理员||价格经理
          this.isLoginUserAsAreaMarket = false;
          this.costShow = true;
          this.netPriceShow = true;
        } else if (userRoleIds.findIndex(e => e == 3) > -1) {
          //大区市场
          this.isLoginUserAsAreaMarket = true;
          this.costShow = false;
          this.netPriceShow = false;
        }
        //其他用户
        else if (userRoleIds.findIndex(e => e == 8) > -1) {
          this.costShow = false;
          this.netPriceShow = false;
        }
        //判断是否有edit权限
        if (
          res[0].pageAccess.findIndex(
            e => e.menuId == currentMenuId && e.access == "modify"
          ) > -1
        ) {
          this.haveEditAccess = true;
        }
      }
    },
    async checkBudgetLock() {
      //判断是否预算锁定
      const { result: res } = await this.$http.get("/Home/GetLock?type=budget");
      if (res == 1) {
        this.isBudgetLocked = true;
      } else {
        this.isBudgetLocked = false;
      }
    },
    editDistribution() {
      if (this.isLoginUserAsAreaMarket == true) {
        this.inputDisable = true;
        this.inputMDRDisable = false;
      } else {
        this.inputDisable = false;
        this.inputMDRDisable = false;
      }
    },
    costChange(row) {
      let getSum = (total, num) => total + num;
      let otherDiscountValue = [];
      row.otherDiscountList.forEach(e =>
        otherDiscountValue.push(+e.otherDiscountValue)
      );
      let sumOtherDiscount = otherDiscountValue.reduce(getSum);
        let netPrice = this.convertDecimal(row.billingPrice * (1 - row.discountPercent / 100 - sumOtherDiscount / 100)) //- row.focPercent / 100
        if (row.saleNetPrice == 0) {
            row.profit = this.convertDecimal(
                (row.netPrice - row.cost) * row.sales
            );
        }
        else {
            row.profit = this.convertDecimal(
                row.netSales - row.cost * row.sales
            );
        };
         //预测-净利润 = (预测-净价格 - 成本)X 预测-销量
      row.isChanged = true;
    },
    //mdrPercentChange(row) {
    //  row.cashPercent = this.convertDecimal(
    //    row.discountPercent - row.mdrPercent
    //  ); //预测现金折扣% = 即时铺货折扣% - 预测MDR%
    //  row.mdrAmount = this.convertDecimal(
    //    (row.mdrPercent / 100) * row.billingPrice
    //  ); //预测MDR金额 = 预测MDR% X 预测发票价
    //  row.mdrAmountSum = this.convertDecimal(
    //    (row.mdrPercent / 100) * row.billingPrice * row.sales
    //  ); //预测MDR总金额 =预测MDR金额X销量
    //  row.cashDiscountAmount = this.convertDecimal(
    //    (row.cashPercent / 100) * row.billingPrice
    //  ); //预测现金折扣金额 = 预测现金折扣% X 预测发票价
    //  row.isChanged = true;
    //  },
      mdrChange(row) {  
          //mdr 现金折扣不能小于0
          if (row.mdrAmount < 0 || row.mdrAmount > row.discountAmount ) {              
              row.mdrAmount = row.discountAmount - row.cashDiscountAmount;
              this.$message.warning("MDR金额和现金折扣不能小于0。");
          }
          else {          
          row.mdrPercent = this.convertDecimal(
              row.mdrAmount / row.billingPrice * 100
          ); //预测MDR% = 预测MDR / 预测发票价
          row.mdrAmountSum = this.convertDecimal(
              row.mdrAmount * row.sales
          ); //预测MDR总金额 =预测MDR金额 X 销量
          row.cashPercent = this.convertDecimal(
              row.discountPercent - row.mdrAmount / row.billingPrice * 100
          ); //预测现金折扣% = 即时铺货折扣% - 预测MDR%
          row.cashDiscountAmount = row.discountAmount - row.mdrAmount;
        //预测现金折扣金额= 铺货折扣金额-MDR金额
              row.isChanged = true;
          }
      },
      discountPercentChange(row) {
          let getSum = (total, num) => total + num;
          let otherDiscountValue = [];
          row.otherDiscountList.forEach(e =>
              otherDiscountValue.push(+e.otherDiscountValue)
          );
          let sumOtherDiscount = otherDiscountValue.reduce(getSum);

          row.netPrice = this.convertDecimal(
              row.billingPrice *
              (1 - row.discountPercent / 100 - sumOtherDiscount / 100)//- row.focPercent / 100
          ); //预测净价格 = 预测发票价 X ( 1 - 预测铺货折扣 – sum(预测其他折扣) - foc)
          if (row.saleNetPrice == 0) {
              row.netSales = this.convertDecimal(
                  row.netPrice *
                  row.sales
              ); //预测-净销售 = 预测-净价格 X 预测-销量
              row.profit = this.convertDecimal(
                  (row.netPrice -
                      row.cost) *
                  row.sales
              ); //预测-净利润 = (预测-净价格 - 成本)X 预测-销量
          }
          else {
          row.profit = this.convertDecimal(
              row.netSales - row.cost * row.sales
              );
          };
          
          //row.grossSales = row.billingPrice * row.sales; //毛销售

          
          //row.mdrAmount = this.convertDecimal(
          //    (row.mdrPercent / 100) * row.billingPrice
          //); //预测MDR金额 = 预测MDR% X 预测发票价
          //row.mdrAmountSum = this.convertDecimal(
          //    (row.mdrPercent / 100) * row.billingPrice * row.sales
          //); //预测MDR总金额 =预测MDR金额X销量      
          row.discountAmount = this.convertDecimal(
              (row.discountPercent / 100) * row.billingPrice
          ); //预测铺货折扣金额 = 预测及时铺货折扣% X 预测发票价 
          
          row.cashPercent = row.discountPercent - row.mdrPercent;
          row.cashDiscountAmount = row.discountAmount - row.mdrAmount;
          row.isChanged = true;
      },
      
    billingPriceChange(row) {
      let getSum = (total, num) => total + num;
      let otherDiscountValue = [];
      row.otherDiscountList.forEach(e =>
        otherDiscountValue.push(+e.otherDiscountValue)
      );
      let sumOtherDiscount = otherDiscountValue.reduce(getSum);

      row.netPrice = this.convertDecimal(
        row.billingPrice *
          (1 - row.discountPercent / 100 - sumOtherDiscount / 100) // - row.focPercent / 100
        ); //预测净价格 = 预测发票价 X ( 1 - 预测铺货折扣 – sum(预测其他折扣) - foc)

        if (row.saleNetPrice == 0) {
            row.netSales = this.convertDecimal(
                row.netPrice *
                row.sales
            ); //预测-净销售 = 预测-净价格 X 预测-销量
            row.profit = this.convertDecimal(
                (row.netPrice -
                    row.cost) *
                row.sales
            ); //预测-净利润 = (预测-净价格 - 成本)X 预测-销量
        }
        else {
            row.profit = this.convertDecimal(
                row.netSales - row.cost * row.sales
            );
        };

      row.grossSales = row.billingPrice * row.sales; //毛销售

      row.mdrAmount = this.convertDecimal(
        (row.mdrPercent / 100) * row.billingPrice
      ); //预测MDR金额 = 预测MDR% X 预测发票价
      row.mdrAmountSum = this.convertDecimal(
        (row.mdrPercent / 100) * row.billingPrice * row.sales
      ); //预测MDR总金额 =预测MDR金额X销量      
      row.discountAmount = this.convertDecimal(
        (row.discountPercent / 100) * row.billingPrice
        ); //预测铺货折扣金额 = 预测及时铺货折扣% X 预测发票价
        row.cashDiscountAmount = row.discountAmount - row.mdrAmount;
        //预测现金折扣金额= 铺货折扣金额-MDR金额

       // row.foc = this.convertDecimal(
        //    (row.focPercent / 100) * row.billingPrice
        //); //foc

      row.isChanged = true;
      },
      /*FOCChange(row) {
          row.foc = this.convertDecimal(row.focPercent * row.billingPrice /100, 2);
          let getSum = (total, num) => total + num;
          let otherDiscountValue = [];
          row.otherDiscountList.forEach(e =>
              otherDiscountValue.push(+e.otherDiscountValue)
          );
          let sumOtherDiscount = this.convertDecimal(
              otherDiscountValue.reduce(getSum)
          );
          row.netPrice = this.convertDecimal(
              row.billingPrice *
              (1 - row.discountPercent / 100 - sumOtherDiscount / 100 - row.focPercent /100)
          ); //预测净价格 = 预测发票价 X ( 1 - 预测铺货折扣 – sum(预测其他折扣))
          if (row.saleNetPrice == 0) {
              row.netSales = this.convertDecimal(
                  row.netPrice *
                  row.sales
              ); //预测-净销售 = 预测-净价格 X 预测-销量
          
          row.profit = this.convertDecimal(
              (row.netPrice -
                  row.cost) *
              row.sales
              );
          }
          else {
              row.profit = this.convertDecimal(
                  row.netSales - row.cost * row.sales
              );
          };
          row.isChanged = true;
      },*/
      conditionChange(row) {
          //if (row.isconditionDiscount == 0 && row.conditionDiscount > row.discountAmount) {
          //    this.$message.warning("不是额外折扣下，有条件折扣不能大于铺货折扣。"); 
          //    row.conditionDiscount = 0;
          //};
          row.isChanged = true;

      },
    otherDiscountChange(row) {
      let getSum = (total, num) => total + num;
      let otherDiscountValue = [];
      row.otherDiscountList.forEach(e =>
        otherDiscountValue.push(+e.otherDiscountValue)
      );
      let sumOtherDiscount = this.convertDecimal(
        otherDiscountValue.reduce(getSum)
      );
      row.netPrice = this.convertDecimal(
        row.billingPrice *
          (1 - row.discountPercent / 100 - sumOtherDiscount / 100)//- row.focPercent / 100
        ); //预测净价格 = 预测发票价 X ( 1 - 预测铺货折扣 – sum(预测其他折扣))

        if (row.saleNetPrice == 0) {
            row.netSales = this.convertDecimal(
                row.netPrice *
                row.sales
            ); //预测-净销售 = 预测-净价格 X 预测-销量
            row.profit = this.convertDecimal(
                (row.netPrice -
                    row.cost) *
                row.sales
            ); //预测-净利润 = (预测-净价格 - 成本)X 预测-销量
        }
        else {
            row.profit = this.convertDecimal(
                row.netSales - row.cost * row.sales
            );
        };
      //row.mdrAmount = this.convertDecimal(
      //  (row.mdrPercent / 100) * row.billingPrice
      //); //预测MDR金额 = 预测MDR% X 预测发票价
      //row.mdrAmountSum = this.convertDecimal(
      //  (row.mdrPercent / 100) * row.billingPrice * row.sales
      //); //预测MDR总金额 =预测MDR金额X销量
      //row.cashDiscountAmount = this.convertDecimal(
      //  (row.cashPercent / 100) * row.billingPrice
      //); //预测现金折扣金额 = 预测现金折扣% X 预测发票价
      //row.discountAmount = this.convertDecimal(
      //  (row.discountPercent / 100) * row.billingPrice
      //); //预测铺货折扣金额 = 预测及时铺货折扣% X 预测发票价
      row.isChanged = true;
    },
    async saveDistribution() {
        if (this.distributionList.filter(e => e.isChanged == true).length == 0) {
            return this.$message.warning("没有任何数据更新，无需保存");
        }
        else {
            let updateOtherDiscountList = [];

            this.distributionList
                .filter(e => e.isChanged == true)
                .forEach(x =>
                    x.otherDiscountList.forEach(y =>
                        updateOtherDiscountList.push({
                            ForecastId: y.forecastId,
                            OtherDiscountYear: y.year,
                            otherDiscountValueId: y.otherDiscountValueId,
                            OtherDiscountHeadId: y.header_Id,
                            OtherDiscountValue: y.otherDiscountValue
                        })
                    )
                );
            this.distributionList
                .filter(e => e.isChanged == true)
                .forEach(x => {
                    x.forecastDiscount = x.discountAmount;
                    x.forecastCashDiscount = x.cashDiscountAmount;
                    x.forecastMDR = x.mdrAmount;
                    x.forecasteMDRAmount = x.mdrAmountSum
                });

            let updateDistribution = this.distributionList.filter(
                e => e.isChanged == true
            );
            //convert swith value
            updateDistribution.forEach(x => {
                if (x.isconditionDiscount == false)
                    x.isconditionDiscount = 0;
                else
                    x.isconditionDiscount = 1;
            });
            const { result: res } = await this.$http.fetchData(
                "Forecast/UpdateForecastDistribution",
                {
                    forecasts: updateDistribution,
                    othersFcstValues: updateOtherDiscountList
                }
            );
            if (res == "success") {
                this.$message.success("保存预测分配数据成功");
                this.getDistributionList(false);
            } else {
                this.$message.error("保存预测分配数据失败");
            }
        }
    },
    async exportDistribution(flag) {
      const { result: fileName } = await this.$http.fetchData(
        "Forecast/GetForecastDistribution",
        {
          brandName: this.searchList.brand,
          productName: this.searchList.productName,
          productLine: this.searchList.productLine,
          agicodeId: !this.searchList.agicodeId
            ? 0
            : +this.searchList.agicodeId,
          areaId: !this.searchList.areaId ? 0 : +this.searchList.areaId,
          saleGroupId: !this.searchList.salegroupId
            ? 0
            : +this.searchList.salegroupId,
          month: this.searchList.month,
          year: this.searchList.year,
          pageNumber: this.queryInfo.pagenum - 1,
          pageSize: this.queryInfo.pagesize,
          isExport: !flag,
          isStored: flag
        }
      );
      if (fileName == "success") {
        return this.$message.success(
          "导出请求已被记录，后面会以邮件形式通知下载。"
        );
      } else {
        this.downloadFileName = fileName;
        var todayDate = new Date();
        const res = await this.$http.downLoad(
          `/Home/Download?fileName=${this.downloadFileName}`
        );
        var blob = new Blob([res.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        var objectUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.setAttribute("style", "display:none");
        a.setAttribute("href", objectUrl);
        a.setAttribute(
          "download",
          `预测分配_${todayDate.getFullYear()}${todayDate.getMonth() +
            1}${todayDate.getDate()}.xlsx`
        );
        a.click();
        URL.revokeObjectURL(objectUrl);
      }
    },
    importDialogClosed() {
      this.$refs.importFormRef.resetFields();
    },
    async submitUpload(param) {
      let file = this.$refs.upload.uploadFiles.pop().raw; //这里获取上传的文件对象
      let formData = new FormData();
      formData.append("file", file);
      try {
        const { result: res } = await this.$http.post(
          "/Forecast/ImportForecastDistribution",
          formData
        );

        if (res == "success") {
          this.importDialogVisible = false;
          return this.$message.success("上传成功");
        } else if (res == "successMail") {
          return this.$message.success(
            "导入请求已被记录，后面会以邮件形式通知。"
          );
        } else if (res.errorType) {
          return this.$message.error("上传失败：" + res.errorType);
        } else if (!!res.errorFilePath) {
          this.$notify({
            title: "导入错误",
            message: res.promptMessage,//"错误文件提示将自动下载，请查看",
            type: "warning",
            position: 'bottom-left'
          }); 
        var todayDate = new Date();
        const errorFile = await this.$http.downLoad(
          `/Home/Download?fileName=${res.errorFilePath}`
        );
        var blob = new Blob([errorFile.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        var objectUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.setAttribute("style", "display:none");
        a.setAttribute("href", objectUrl);
        a.setAttribute(
          "download",
          `预测分配导入失败_${todayDate.getFullYear()}${todayDate.getMonth() +
            1}${todayDate.getDate()}.xlsx`
        );
        a.click();
        URL.revokeObjectURL(objectUrl);
        


        } else {
          return this.$message.error("上传失败");
        }
      } catch {
        return this.$message.error("上传失败");
      }
    },
    handleSelectChange() {
      this.$forceUpdate();
    },
    getCurrentDate() {
      let date = new Date();
      this.currentYear = date.getFullYear(); //获取完整的年份(4位)
      this.searchList.year = this.currentYear;
      this.currentMonth = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
    },
    async areaChange(){
     if(!!this.searchList.areaId)
      {
       this.searchList.salegroupId = null
       const { result: sglist } = await this.$http.get(
        `/Home/GetSelectListLinkage?lookup=salegroup&linkid=${this.searchList.areaId}`
       );
      this.saleGroupDDL = sglist;
      }
      },
    //add by lance 20240118
      async exportDistributionYearCompare() {
          const { result: fileName } = await this.$http.fetchData(
              "Forecast/GetForecastDistributionYearCompare",
              {
                  year: this.searchList.year              
              }
          );
          if (fileName == "success") {
              return this.$message.success(
                  "导出请求已被记录，后面会以邮件形式通知下载。"
              );
          } 
      }
  }
};
</script>