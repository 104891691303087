import axios from 'axios'
import { Message,Loading  } from 'element-ui'
//import NProgress from 'nprogress'
//import 'nprogress/nprogress.css'

axios.defaults.withCredentials = true;
let loading;
let loadingNum=0;

function startLoading() {    
	if(loadingNum==0){
		loading = Loading.service({
		  lock: true,
		  text: '加载中...',
		  background:'rgba(255,255,255,0.5)',
		})
	}
	//请求数量加1
	loadingNum++;
}
function endLoading() {
    //请求数量减1
	loadingNum--
	if(loadingNum<=0){
		loading.close()
	}
}

//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
//axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.interceptors.request.use((config) => {
    //NProgress.start()
    startLoading();
    config.baseURL = "https://dms.syngentachina.com"; // "https://webapi-uat.dms.syngentachina.com" //; //"https://localhost:44343";  //;
    if (!!window.sessionStorage.getItem('dmstoken')) {
        config.headers.Authorization = 'Bearer ' + window.sessionStorage.getItem('dmstoken').toString()
        console.log('Bearer ' + window.sessionStorage.getItem('dmstoken').toString());
    }
    return config;
}, (err) => {
    console.log(error)
    // 发生错误做的一些事情
    return Promise.reject(error);
})



// 添加响应拦截器
axios.interceptors.response.use(function (res) {
    //NProgress.done()
      endLoading();
    // 对响应数据做点什么
    if (res && res.data.code == "200") {
        return res.data
    }
    else if (res) {
        return res
    }
    else {
        return Message.error("错误")
    }
}, function (error) {
    // 对响应错误做点什么;没有权限401，去登录界面
    /*   if(error.response.status === 401) {
             window.$toast('登录超时')
             setTimeout(() => {
                 uni.switchTab({  
                     url: '/pages/home/home'
                 })
             },1000)
         }else if(error.response.status === 403) {
             window.$toast('暂无权限')
         }else {
             window.$toast('网络错误')
         } */

    return Promise.reject(error);
});

axios.fetchData = function (url, params)  //fetchData for post json stringify data
{
    return axios.post(url, serializeData(params))
};
axios.downLoad = function (url) {
    return axios.get(url, { responseType: 'blob' })
}




function serializeData(data) {
    if (!(data instanceof Object)) {
        return ((data == null) ? "" : data.toString());
    }
    var buffer = [];
    for (var name in data) {
        if (!data.hasOwnProperty(name)) {
            continue;
        }
        var value = data[name];
        if (value instanceof Object) {
            value = JSON.stringify(value);
        }
        buffer.push(
            encodeURIComponent(name) + "=" + encodeURIComponent((value == null) ? "" : value)
        );
    }
    var source = buffer.join("&").replace(/%20/g, "+");
    return (source);
}

export default axios